import { ref, readonly, reactive } from 'vue';
import { defineStore } from 'pinia';

// import type { Ref } from 'vue';
import * as pf from '@amirada/lib-shared';

type TQuery = {
  psx?: string,
  dops?: number,
  cops?: number,
  rcst?: string,
  rcap?: string,
}
type TParams = {
  items: string[],
  slugs: string[],
  slug: string;
  href: string;
  path: string;
  lcda: string;
  rcco: string;
  hash: string;
  query: TQuery;
};

const paramsData: TParams = {
  items: [],
  slugs: [],
  href: '',
  slug: '',
  path: '',
  lcda: '',
  rcco: '',
  hash: '',
  query: { dops: 1547, cops: 3 }
};

// https://pinia.vuejs.org/core-concepts/#Setup-Stores
export const usePfinderParams = defineStore('pfinderParams', () => {
  const { currentRoute } = useRouter();
  // usinf a single reactive object allowes us to trigger change just once for change of current, previous and changes
  const params = reactive({
    curr: paramsData,
    prev: {} as TParams
    // change: paramsData as Partial<TParams>
  });

  const update = (route: typeof currentRoute.value) => {
    const items = (route.params.slugs || []) as string[];
    // remove empty path item if any
    while (items[items.length - 1] === '') { items.pop(); }
    params.prev = params.curr;
    const query = { ...route.query } || {};
    // parse string values of query as json - where possible
    Object.keys(query).forEach((key) => { 
      try { query[key] = JSON.parse(query[key] as string); } catch (err) { /* conversion not possible - keep original */ } 
    });
    params.curr = {
      items,
      slugs: items.slice(2),
      href: route.fullPath,
      path: items.slice(2).join('/'),
      slug: items.length > 2 ? items[items.length - 1] : '',
      lcda: items.length >= 1 ? items[0] : '',
      rcco: items.length >= 2 ? items[1] : '',
      hash: '' + route.hash,
      query
    };
    
    /*
    const getChanged = (curr: Record<string, unknown>, prev: Record<string, unknown>, change: any = {}) => {
      Object.keys(curr).forEach((key: string) => {
        if (curr[key] instanceof Object && JSON.stringify(curr[key]) !== JSON.stringify(prev[key])) {
          if (!(prev[key] instanceof Object)) {
            // add hole deep cloned object
            change[key] = JSON.parse(JSON.stringify(curr[key]));
          } else {
            // recursively check sub object
            change[key] = getChanged(curr[key] as typeof curr, prev[key] as typeof prev, curr[key] instanceof Array ? [] : {});
          }
        } else if (curr[key] !== prev[key]) {
          change[key] = curr[key];
        }
      });
      return change;
    };

    const setRemoved = (curr: Record<string, unknown>, prev: Record<string, unknown>, change: any) => {
      Object.keys(prev).forEach((key: string) => {
        if (prev[key] instanceof Object) {
          if (key === 'items') {
            const top = 1;
          }
          if (!(curr[key] instanceof Object)) {
            change[key] = undefined;
          } else {
            change[key] = key in change ? change[key] : prev[key] instanceof Array ? [] : {};
            change[key] = setRemoved(curr[key] as typeof curr, prev[key] as typeof prev, change[key]);
          }
        } else if (!(key in curr)) {
          change[key] = undefined;
        }
      });
      return change;
    };

    const changed = getChanged(params.curr, params.prev);
    params.change = setRemoved(params.curr, params.prev, changed);
    */
  };

  watch(currentRoute, update);
  update(currentRoute.value);
  return { params };
});
