import revive_payload_client_YTGlmQWWT5 from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/clients/devclient1/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_SeG0EjL5Ec from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import my_plugin_p5FYNA9QtD from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/base/plugins/my-plugin.ts";
import pfinderPlugin_6MjbnZQiQX from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/base/plugins/pfinderPlugin.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  chunk_reload_client_SeG0EjL5Ec,
  my_plugin_p5FYNA9QtD,
  pfinderPlugin_6MjbnZQiQX
]