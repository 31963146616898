export default defineAppConfig({
  api: {
    // url: 'http://live.amirada.com/pfinder',
    url: 'http://localhost:3000/pfinder'
  },
  bar: 'base',
  baz: 'base',
  array: () => [
    'base',
    'base',
    'base'
  ],
  baseObject: {
    baseObjectNested: {
      baseArray: [
        "base",
        "base",
        "base"
      ]
    }
  }
});

declare module '@nuxt/schema' {
  interface AppConfigInput {
      api: {
          url: string
      }
  }
}
