import { ref, readonly, reactive } from 'vue';
import { defineStore } from 'pinia';

// import type { Ref } from 'vue';
import * as pf from '@amirada/lib-shared';
import { COPS } from '~/constants/cops';
// https://pinia.vuejs.org/core-concepts/#Setup-Stores
export const usePfinderData = defineStore('pfinderData', () => {
  const api = usePfinderAPI();
  const { params } = usePfinderParams();

  type Data = {
    // stage?: string,
    statusData?: unknown,
    currentData?: unknown,
    initData?: pf.InitData,
    pathData?: Array<pf.Node>
    maskData?: pf.MaskNode,
    listData?: pf.ListNode,
    detailData?: pf.DetailNode,
    classData?: pf.Node,
    configData?: pf.ConfigNode,
    undefinedData?: unknown
  };

  const data = reactive({
    statusData: undefined,
    currentData: undefined,
    initData: undefined,
    pathData: undefined,
    restData: undefined,
    configData: undefined,
    maskData: undefined,
    listData: undefined,
    detailData: undefined,
    classData: undefined,
    undefinedData: undefined
  } as Data);

  // type TCache = Record<keyof Data, Record<string, unknown>>;
  const cache = reactive({
    initData: {} as {[key: string]: pf.InitData},
    pathData: {} as {[key: string]: pf.PathNodes},
    maskData: {} as {[key: string]: pf.MaskNode},
    listData: {} as {[key: string]: pf.ListNode}
  });

  const setData = <K extends keyof Data>(type: K, value: Data[K]) => {
    data[type] = value;
  };

  const nodeTypeToKey = (node: pf.Node): keyof typeof data => {
    switch (node.nodetype) {
      case pf.NodeType.nt_root:
      case pf.NodeType.nt_WebClass: return 'classData';
      case pf.NodeType.nt_Configuration: return 'configData';
      case pf.NodeType.nt_SearchMask: return 'maskData';
      case pf.NodeType.nt_HitList: return 'listData';
      case pf.NodeType.nt_ConfigData:
      case pf.NodeType.nt_HitDetails: return 'detailData';
      default: return 'undefinedData';
    }
  };

  watch(() => data.initData, () => {
    if (data.initData) {
      data.pathData = data.initData.pathData;
      // setData('pathData', data.initData.pathData);
    }
  });

  watch(() => data.pathData, () => {
    if (data.pathData) {
      // setData('currentData', data.pathData[data.pathData.length - 1]);
      data.currentData = data.pathData[data.pathData.length - 1];
      data.pathData.forEach((node) => {
        const nodeKey = nodeTypeToKey(node);
        if (nodeKey === 'configData') {
          node.nodes?.forEach((childNode) => { // childNode: nt_SearchMask/maskData | nt_HitList/listData | nt_ConfigData/detailData
            setData(nodeTypeToKey(childNode), childNode);
          });
        }
        setData(nodeKey, node);
      });
    }
  });

  const argsToKey = (args: any, obj: Record<string, any>) => {
    let cacheKey: string;
    try {
      cacheKey = JSON.stringify(args);
    } catch (err) {
      console.error(err);
      cacheKey = 'err';
    }
    // to make thinks easy we just remove old cached data if client side caching is turned of
    if ((params.curr.query?.cops || 0) & COPS.cachingOff) {
      delete obj[cacheKey];
    }
    return cacheKey;
  };

  type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any

  const getInit = async (...args: Parameters<typeof api.getInit>) => {
    const ck = argsToKey(args, cache.initData);
    return cache.initData[ck] as AsyncReturnType<typeof api.getInit> || (cache.initData[ck] = await api.getInit.apply(null, args));
  };
  const getPath = async (...args: Parameters<typeof api.getPath>) => {
    const ck = argsToKey(args, cache.pathData);
    return cache.pathData[ck] as AsyncReturnType<typeof api.getPath> || (cache.pathData[ck] = await api.getPath.apply(null, args));
  };
  const getMask = async (...args: Parameters<typeof api.getMask>) => {
    const ck = argsToKey(args, cache.maskData);
    return cache.maskData[ck] as AsyncReturnType<typeof api.getMask> || (cache.maskData[ck] = await api.getMask.apply(null, args));
  };
  const getList = async (...args: Parameters<typeof api.getList>) => {
    const ck = argsToKey(args, cache.listData);
    return cache.listData[ck] as AsyncReturnType<typeof api.getList> || (cache.listData[ck] = await api.getList.apply(null, args));
  };

  return { data, cache, setData, getInit, getPath, getMask, getList };
});
