import * as pf from '@amirada/lib-shared';
import { usePfinderState } from '~/stores/pfinderState';
import { usePfinderData } from '~/stores/pfinderData';
import { usePfinderParams } from '~/stores/pfinderParams';

export default defineNuxtPlugin(async (nuxtApp) => {
  const store = usePfinderData(); // make read only
  const { state } = usePfinderState();
  const { params } = usePfinderParams();
  const router = useRouter();
  type TQuery = typeof params.curr.query;

  watch(() => params.curr, (change) => {
    const { curr, prev } = params;
    const restrictChange = curr.rcco !== prev.rcco || curr.query.rcap !== prev.query.rcap || curr.query.rcst !== prev.query.rcst; 
    const languageChange = curr.lcda !== prev.lcda;
    const serverOptionsChange = curr.query.dops !== prev.query.dops;

    if (languageChange || restrictChange || serverOptionsChange) {
      return store.getInit({ 
        path: curr.path, 
        lcda: curr.lcda, 
        rcco: curr.rcco,
        rcap: curr.query.rcap, 
        rcst: curr.query.rcst,
        dops: curr.query.dops
      }).then((res) => { store.setData('initData', res); });
    }

    if (curr.path !== prev.path) {
      return store.getPath({ path: change.path, lcda: curr.lcda })
        .then((res) => { store.setData('pathData', res); });
    }

    if (store.data.currentData === store.data.configData) {
      const configName = store.data.configData?.nodename;
      if (configName) {
        store.getMask({ conf: configName, psx: curr.query.psx, lcda: curr.lcda })
          .then((res) => { store.setData('maskData', res); });
        store.getList({ conf: configName, psx: curr.query.psx, lcda: curr.lcda })
          .then((res) => { store.setData('listData', res); });
      }
    }
  });


  const initData = async () => {
    const { curr, prev } = params;
    console.log('pfinderPlugin initData ...');
    const initData = await store.getInit({ 
      path: curr.path, 
      lcda: curr.lcda, 
      rcco: curr.rcco,
      rcap: curr.query.rcap, 
      rcst: curr.query.rcst,
      dops: curr.query.dops
    }).then((initData) => {
      store.setData('initData', initData);
      store.setData('pathData', initData.pathData);
    });
  };

  await initData();

  // point of bundeling utiles in an object is to make it possible for methods to call sibling methods like this/util,
  // as well as provide in return :)
  const util = {
    nodeToPath: (node: pf.Node, options: any = {}) => {
      const route = router.currentRoute.value;
      const pathNodes = (store.data.pathData || []);
      let nodePathNodes: Array<pf.Node> = [...pathNodes, node];
      let index = pathNodes.indexOf(node);
      if (index > -1) { // is a path node
        nodePathNodes = pathNodes.slice(0, index);
      } else { // is a child of a path node
        const parentNode = pathNodes.find((pathNode) => {
          // nodes of Configuration are HitList, Mask, and ConfData but only HitList is of intressed for path creation
          if (pathNode.nodetype === pf.NodeType.nt_Configuration) {
            const hitListNode = pathNode.nodes?.find(node => node.nodetype === pf.NodeType.nt_HitList);
            return hitListNode ? hitListNode.nodes?.includes(node) : false;
          } else {
            return pathNode.nodes?.includes(node);
          }
        });
        if (parentNode) {
          index = pathNodes.indexOf(parentNode);
          nodePathNodes = [...pathNodes.slice(0, index + 1), node];
        }
      }
      const queryTuples = Object.entries(route.query);
      const searchStr = queryTuples.length === 0 ? '' : '?' + queryTuples.map(([key, val]) => key + '=' + val).join('&');
      // remove root13
      nodePathNodes.shift();
      return `/${params.curr.lcda}/${params.curr.rcco}/${nodePathNodes.map(pathNode => pathNode.meta?.slug).join('/')}${searchStr}${route.hash}`;
    },
    navigateToNode: (node: pf.Node) => {
      router.push(util.nodeToPath(node));
    },
    setLanguage (cod: string) {
      const route = router.currentRoute.value;
      const pathArr = route.path.split('/');
      pathArr[1] = cod.toLocaleLowerCase();
      router.push({
        path: pathArr.join('/'),
        query: route.query,
        hash: route.hash
      });
    },
    setCountry (cod: string) {
      const route = router.currentRoute.value;
      const pathArr = route.path.split('/');
      pathArr[2] = cod.toLocaleLowerCase();
      router.push({
        path: pathArr.join('/'),
        query: route.query,
        hash: route.hash
      });
    },
    setOps (type: 'dops'|'cops', value: number) {
      util.setQuery(router.currentRoute.value.query, { [type]: value });
    },
    setRestriction (type: keyof pf.RestData, cod: string) {
      const route = router.currentRoute.value;
      if (type === 'rcco') {
        util.setCountry(cod);
      } else {
        util.setQuery(route.query, { [type]: cod });
      }
    },
    setQuery (...objects: TQuery[]) {
      const route = router.currentRoute.value;
      const query = [...objects].reduce((prev, curr) => Object.assign(prev, curr), {} as TQuery);
      Object.entries(query).map(([key, value]) => (value === undefined || value === '') && delete query[key as keyof TQuery]);
      router.push({
        path: route.path,
        query,
        hash: '#' + Math.floor(Math.random() * 99999999) // route.hash
      });
      /*
      const query = Object.assign(route.query, { ['rc' + type]: cod });
      const queryTuples = Object.entries(query);
      const searchStr = queryTuples.length === 0 ? '' : '?' + queryTuples.map(([key, val]) => key + '=' + val).join('&');
      const fullPath = `${route.path}${searchStr}${route.hash}`;
      // https://stackoverflow.com/questions/66317718/nuxtjs-change-query-params-and-reload-page
      window.history.pushState({}, '', fullPath);
      router.push(fullPath);
      */
    }
  };

  return {
    provide: {
      pf: {
        store,
        state,
        params,
        util
      }
    }
  };
});
