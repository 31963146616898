// import { useFetch } from '#app'
import { reactive } from 'vue';
// import type { Ref } from 'vue';
import * as pf from '@amirada/lib-shared';

export function usePfinderAPI () {
  const appConfig = useAppConfig();
  const app = useNuxtApp();
  const cfg = appConfig.api;

  type RequestType = "init"|"path"|"mask"|"list"|"detail";

  const fetchData = async<T>(type: RequestType /* pf.RequestType */, args: any): Promise<T> => {
    args.dops = args.dops || (app.$pf && app.$pf.params.curr.query.dops);
    // args.stage = app.$pf.params.curr.query.stage;
    args = cleanArgs(args);

    if (!args.rcco) {
      args.rcco = "0";
    }

    const url = `${cfg.url}/${type}?${argsToStr(args)}`;
    return new Promise((resolve, reject) => {
      const opts: RequestInit = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      fetch(url, opts)
        .then((res): Promise<pf.RespondsData & {result: T}> => res.json())
        .then((json) => {
          if (json.status !== 200) {
            return reject(json);
          }
          const result = json.result;
          // data.currentData = result;
          // data.statusData = { ...data, request: { type, args, url } };
          resolve(result);
        })
        .catch(reject);
    });
  };

  const cleanArgs = <T extends Record<string, unknown>>(args: T): Partial<T> => {
    const clean: Partial<T> = {};
    (Object.keys(args) as Array<keyof T>)
      .filter(arg => args[arg] !== undefined && args[arg] !== '')
      .forEach((arg) => { clean[arg] = args[arg]; });
    return clean;
  };

  const argsToStr = <T extends Record<string, any>>(args: T): string => {
    return (Object.keys(args) as Array<string>)
      .filter(arg => args[arg] !== undefined && args[arg] !== '')
      .map(arg => `${arg}=${encodeURIComponent(args[arg])}`)
      .join('&');
  };

  const getInit = async (args: {path?:string, lcda?: string, rcco?: string, rcap?: string, rcst?: string, dops?: number} = {}): Promise<pf.InitData> => {
    return await fetchData<pf.InitData>('init', args);
  };
  const getPath = async (args: {lcda?: string, path?: string, conf?: string}): Promise<pf.PathNodes> => {
    return await fetchData<pf.PathNodes>('path', args);
  };
  const getMask = async (args: {lcda?: string, conf?: string, psx?: string}): Promise<pf.MaskNode> => {
    return await fetchData<pf.MaskNode>('mask', args);
  };
  const getList = async (args: {lcda?: string, conf?: string, psx?: string, cursor?: number, limit?: number}): Promise<pf.ListNode> => {
    return await fetchData<pf.ListNode>('list', args);
  };
  const getDetail = async (args: {lcda?: string, conf?: string, nodename: string}): Promise<pf.DetailNode> => {
    return await fetchData<pf.DetailNode>('detail', args);
  };


  return {
    getInit,
    getPath,
    getMask,
    getList,
    getDetail
  };
}
