import { default as _91_46_46_46slugs_9385wXl6f4dBMeta } from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/clients/devclient1/pages/[...slugs].vue?macro=true";
import { default as fooikIujJw0t3Meta } from "C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/base/pages/foo.vue?macro=true";
export default [
  {
    name: _91_46_46_46slugs_9385wXl6f4dBMeta?.name ?? "slugs",
    path: _91_46_46_46slugs_9385wXl6f4dBMeta?.path ?? "/:slugs(.*)*",
    meta: _91_46_46_46slugs_9385wXl6f4dBMeta || {},
    alias: _91_46_46_46slugs_9385wXl6f4dBMeta?.alias || [],
    redirect: _91_46_46_46slugs_9385wXl6f4dBMeta?.redirect,
    component: () => import("C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/clients/devclient1/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: fooikIujJw0t3Meta?.name ?? "foo",
    path: fooikIujJw0t3Meta?.path ?? "/foo",
    meta: fooikIujJw0t3Meta || {},
    alias: fooikIujJw0t3Meta?.alias || [],
    redirect: fooikIujJw0t3Meta?.redirect,
    component: () => import("C:/Users/Gregor/Documents/projects/amirada/pfinder_nuxt3_monorepo/base/pages/foo.vue").then(m => m.default || m)
  }
]