export default defineAppConfig({
  api: {
    url: 'https://live.amirada.com/pfinder'
    // url: 'http://localhost:3000/pfinder'
  },
  cfgFoo: {
    str: 'hallo',
    num: 123,
    arr: [1, 2, 3]
  },
  baseObject: {
    baseObjectNested: {
      baseArray: [
        "extended",
        "extended",
        "extended"
      ],
      extArray: [
        "extended",
        "extended",
        "extended"
      ]

    }
  }
});

declare module '@nuxt/schema' {
    interface AppConfigInput {
        cfgFoo?: {
            /** Project name */
            str: string,
            num: number,
            arr: number[]
        }
    }
}
